import styled from "styled-components";
import { Breakpoints } from "config-styles";

export const Wrapper = styled.div``;
export const Header = styled.h3`
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;

  ${Breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const Col = styled.div`
  //width: 50%;
`;

export const Alert = styled.div`
  text-align: center;
  padding: 15px 55px;
  font-weight: 400;

  ${Breakpoints.desktop} {
    margin-top: 45px;
  }

  ${Breakpoints.tabletMax} {
    margin-top: 5px;
  }
`;
