import React from "react";
import { Wrapper, Content, Text } from "./styles";

function Price({ value }) {
  return (
    <Wrapper>
      <Content>
        <strong>
          <sup>R$</sup>
          {value}
        </strong>
      </Content>
      <Text>/por mês</Text>
    </Wrapper>
  );
}

export default Price;
