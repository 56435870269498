import React from "react";
import { Wrapper, Content, Link } from "./styles";
import { ActionModals } from "containers/ModalRoot/module";
import { useModalState } from "containers/ModalProvider";
import { usePlan } from "components/PlanView/context";
import { formatter } from "../../helpers";

function Footer() {
  const { data } = usePlan();
  const [, dispatch] = useModalState();

  const openModal = () => {
    dispatch(ActionModals.openModalPortalConditions());
  };

  return (
    <Wrapper>
      <Content>
        <p>*Usuários adicionais custam R$19,90 por mês.</p>
        <p>
          **A integração com DWV e/ou Órulo é opcional e custa R$24,90 por mês
          cada um.
        </p>
        <p>
          ***A gratuidade do portal Imovelweb é oferecido pelo portal aos
          clientes do Tecimob. <Link onClick={openModal}>Ler condições</Link>
        </p>
      </Content>
    </Wrapper>
  );
}

export default Footer;
