import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 25px;
`;
export const Content = styled.div`
  max-width: 1124px;
  margin: 0 auto;
  font-weight: 400;

  p {
    color: #2b343d;
  }
`;

export const Link = styled.span`
  cursor: pointer;
  color: #0060c3;
  text-decoration: underline;
`;
