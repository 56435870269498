import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const Content = styled.div`
  sup {
    font-size: 16px;
    vertical-align: text-top;
    color: #272727;
    margin-right: 5px;
    margin-left: -20px;
    font-weight: 600;
  }

  strong {
    color: #0060c3;
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
  }
`;
export const Text = styled.div`
  text-align: center;
`;
