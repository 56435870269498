import React from "react";
// Components
import MainTitle from "components/MainTitle";
// Styles
import { Container } from "components/Layout/styles";
import {
  Wrapper,
  Doubts,
  Item,
  Title,
  Text,
} from "sections/planos/FrequentlyQuestions/styles";
import { QUESTIONS } from "./data";

function FrequentlyQuestions() {
  return (
    <Wrapper color="#F1F5FC">
      <Container>
        <MainTitle title="Dúvidas Frequentes" />
        <Doubts>
          {QUESTIONS.map(({ id, name, acceptedAnswer }) => (
            <Item key={id}>
              <Title>{name}</Title>
              <Text>{acceptedAnswer}</Text>
            </Item>
          ))}
        </Doubts>
      </Container>
    </Wrapper>
  );
}

export default FrequentlyQuestions;
