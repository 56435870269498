const MAX_USERS = 99999;
const MAX_MONTHS = 12;
const SIGNATURE_PRICE = 50.0;
const USER_PRICE = 19.9;
const MAX_INSTALLMENTS_SLIP = 4;
const MIN_VALUE_SLIP = 187.0;
const MAX_INSTALLMENTS_CREDIT_CARD = 5;
const MIN_VALUE_CREDIT_CARD = 150.0;

export default class Plan {
  /**
   * Verifica se o plano tem algum desconto
   * @returns {boolean}
   */
  hasDiscount() {
    return this.getGrossValue() >= 400;
  }

  /**
   * A quantidade de usuários no plano
   * @param {Number} newUsers - quantidade de usuários do plano
   */
  set users(newUsers) {
    if (newUsers > MAX_USERS) {
      throw new Error(
        `Quantidade de usuários não pode ser maior que ${MAX_USERS}`
      );
    }

    this._users = newUsers;
  }

  /**
   * Quantidade de meses do plano
   * @param {Number} newMonths
   */
  set months(newMonths) {
    if (newMonths <= 0) {
      throw new Error("Quantidade de meses não pode ser menor ou igual à 0");
    }
    if (newMonths > MAX_MONTHS) {
      throw new Error("Quantidade de meses não pode maior que MAX_MONTHS");
    }

    this._months = newMonths;
  }

  /**
   * Retorna o nome do plano
   */
  getName() {
    switch (this._months) {
      case 12:
        return "Anual";
      case 6:
        return "Semestral";
      case 3:
        return "Trimestral";
      default:
        return "Mensal";
    }
  }

  getInstallmentsSlip() {
    if (this._months === 1) return 1;
    const MAX = this._months === 3 ? 2 : MAX_INSTALLMENTS_SLIP;

    const installments = Math.trunc(this.getNetValue() / MIN_VALUE_SLIP);

    return installments > MAX ? MAX : installments;
  }

  getInstallmentsCreditCard() {
    // Se a quantidade de meses for 1
    // sempre retorna 1 parcela
    if (this._months === 1) return 1;
    const MAX = this._months === 3 ? 2 : MAX_INSTALLMENTS_CREDIT_CARD;

    const installments = Math.trunc(this.getNetValue() / MIN_VALUE_CREDIT_CARD);

    return installments > MAX ? MAX : installments;
  }

  /**
   * Retorna quanto está pagando por mês bruto
   * @returns {number}
   */
  getGrossMonthlyPayment() {
    return this.getGrossValue() / this._months;
  }

  /**
   * Retorna quanto está pagando por mês liquido
   * @returns {number}
   */
  getNetMonthlyPayment() {
    return this.getNetValue() / this._months;
  }

  getDiscountPercentage() {
    let grossValue = this.getGrossValue();
    let discount = 0;

    // Não aplica o desconto se for menor que R$ 400.00
    if (grossValue < 400.0) return discount;

    // Aplica o desconto de 5%
    // se o valor bruto for entre R$ 400.00 e R$ 700.00
    if (grossValue >= 400.0 && grossValue < 700.0) return 0.05;

    // Aplica o desconto de 10%
    // se o valor bruto for entre R$ 700.00 e R$ 1000.00
    if (grossValue >= 700.0 && grossValue < 1000.0) return 0.1;

    // Aplica o desconto de 15%
    // se o valor bruto for entre R$ 900.00 e R$ 1300.00
    if (grossValue >= 1000.0 && grossValue < 1300.0) return 0.15;

    // Aplica o desconto de 20%
    // se o valor bruto for entre R$ 1200.00 e R$ 1600.00
    if (grossValue >= 1300.0 && grossValue < 1600.0) return 0.2;

    // Aplica o desconto de 25%
    // se o valor for entre R$ 1500.00 e R$ 1900.00
    if (grossValue >= 1600.0 && grossValue < 1900.0) return 0.25;

    // Aplica o desconto de 30%
    // se o valor for maior que 1900.00
    return 0.3;
  }

  /**
   * Calcula o desconto total do plano
   * @returns {number}
   */
  getDiscount() {
    let grossValue = this.getGrossValue();

    return grossValue * this.getDiscountPercentage();
  }

  /**
   * Calcula o valor bruto dos usuários
   * @returns {number}
   */
  getUserPrice() {
    return this._users * USER_PRICE;
  }

  /**
   * Calcula o valor bruto
   * @returns {number} - valor total do plano sem os descontos
   */
  getGrossValue() {
    return (SIGNATURE_PRICE + this.getUserPrice()) * this._months;
  }

  getTotalByParcelas(numeroParcelas) {
    return this.getNetValue() / numeroParcelas;
  }

  /**
   * Calcula o valor liquido
   * @returns {number}
   */
  getNetValue() {
    return this.getGrossValue() - this.getDiscount();
  }
}
