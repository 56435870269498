import styled from "styled-components";
import { Breakpoints } from "config-styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 80px;
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  max-width: 1108px;
  margin: 0 auto;
  grid-template-columns: 490px 1fr;
  grid-gap: 40px;

  ${Breakpoints.tabletMax} {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;
//
// export const ColLeft = styled.div`
//   width: 50%;
// `;
// export const ColRight = styled.div`
//   width: 50%;
// `;
