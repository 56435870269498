import styled from "styled-components";
import { Section } from "components/Layout/styles";
import { H4 } from "components/Typograph";

export const Wrapper = styled(Section)`
  color: #fff;
`;

export const Doubts = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Item = styled.li`
  width: 100%;

  @media all and (min-width: 40em) {
    max-width: 445px;

    &:nth-child(2n + 2) ~ & {
      margin-top: 60px;
    }
  }

  @media all and (max-width: 40em) {
    margin-bottom: 20px;
  }
`;

export const Title = styled(H4).attrs(() => ({
  as: "h3"
}))`
  color: #272727;
  font-size: 18px;
  margin-bottom: 0.5em;
`;

export const Text = styled.p`
  color: #3d3d3d;
  font-size: 14px;
  line-height: 30px;
`;
