import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";
// Helpers
import Plan from "helpers/Plan";
// styles
import {
  Wrapper,
  Price,
  ColRadio,
  ColPrice,
  ColDetails,
  ColPromotion,
  Label,
} from "./styles";
import { formatter, getTitle, getText } from "./helpers";

const defaultProps = {
  title: null,
  month: null,
  users: null,
  selected: null,
  onClick: null,
};

const propTypes = {
  title: PropTypes.string,
  month: PropTypes.number,
  users: PropTypes.number,
  selected: PropTypes.number,
  onClick: PropTypes.func,
};

// Armazena a instancia do plano em uma variável
const plan = new Plan();

function MontlyValue({ title: textTitle, month, users, selected, onClick }) {
  const [state, setState] = useState({
    netMonthlyPrice: null,
    grossMonthlyPrice: null,
    totalNetValue: null,
    totalGrossValue: null,
    percentage: null,
  });

  // Atualiza os preços
  useEffect(() => {
    const updatePrices = () => {
      plan.users = users;
      plan.months = month;

      setState({
        netMonthlyPrice: formatter.format(plan.getNetMonthlyPayment()),
        grossMonthlyPrice: formatter.format(plan.getGrossMonthlyPayment()),
        totalNetValue: formatter.format(plan.getNetValue()),
        totalGrossValue: formatter.format(plan.getGrossValue()),
        percentage: plan.getDiscountPercentage(),
        installmentsSlip: plan.getInstallmentsSlip(),
        installmentsCreditCard: plan.getInstallmentsCreditCard(),
        name: plan.getName(),
      });
    };

    updatePrices();
  }, [month, users]);

  const handleClick = () => {
    if (onClick) onClick({ month, users, currentSelected: selected });
  };

  // Verifica se o mês atual está selecionado
  const isSelected = month === selected;

  return (
    <ThemeProvider
      theme={{
        isChecked: isSelected,
      }}
    >
      <Wrapper onClick={handleClick}>
        <ColRadio>
          {isSelected ? <MdCheckCircle /> : <MdRadioButtonUnchecked />}
        </ColRadio>
        <ColPrice width={100}>
          <h4>{state.name}</h4>
          <p>R$ {state.totalNetValue}</p>
        </ColPrice>
        <ColDetails>
          <p className="h-margin-bottom--5">
            Equivalente à <Price>R$ {state.netMonthlyPrice}</Price>/mês
          </p>
          <p>
            {month === 1 ? (
              <em>Pago mensalmente</em>
            ) : (
              <em>
                em {state.installmentsSlip}x no boleto ou{" "}
                {state.installmentsCreditCard}x no cartão
              </em>
            )}
          </p>
        </ColDetails>
        <ColPromotion>
          {state.percentage > 0 && (
            <Label color="primary">Poupe {state.percentage * 100}%</Label>
          )}
        </ColPromotion>
      </Wrapper>
    </ThemeProvider>
  );
}

MontlyValue.defaultProps = defaultProps;
MontlyValue.propTypes = propTypes;

export default MontlyValue;
