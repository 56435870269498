import React from "react";
import CardPlan from "../CardPlan";
import CardYearly from "../CardYearly";
import { Wrapper, Header, Content, Col, Alert } from "./styles";
import { motion } from "framer-motion/dist/framer-motion";
import { usePlan } from "components/PlanView/context";

export default function Resources() {
  return (
    <Wrapper>
      <Header>Por apenas:</Header>
      <Content>
        <motion.div
          style={{ opacity: 0 }}
          animate={{ y: [-20, 0], opacity: [0, 1] }}
          transition={{ ease: "easeInOut" }}
        >
          <CardPlan title="Plano Mensal" price="99,90" />
        </motion.div>
        <motion.div
          style={{ opacity: 0 }}
          animate={{ y: [-20, 0], opacity: [0, 1] }}
          transition={{ ease: "easeInOut", delay: 0.2 }}
        >
          <CardYearly />
        </motion.div>
      </Content>
      <Alert>
        O Tecimob tem tudo o que você precisa pra divulgar seus imóveis na
        Internet e ter o melhor relacionamento com seu cliente!
      </Alert>
    </Wrapper>
  );
}
