import React from "react";
import { MdCheck } from "react-icons/md";
// Components
import { Container } from "components/Layout/styles";
import { H2, P } from "components/Typograph";
// Styles
import {
  Wrapper,
  List,
  BoxCenter,
  BoxLeft,
  BoxRight,
  Spacer
} from "sections/global/Cta/styles";
import Signup from "../../../components/Signup/index";

function Cta() {
  return (
    <Wrapper>
      <Container>
        <BoxLeft>
          <H2 as="h3">Vamos começar!</H2>
          <P>
            Com um conjunto de ferramentas pensado para o corretor de imóveis,
            você terá tempo de sobra para focar no que realmente importa: vender
            mais.
          </P>
        </BoxLeft>
        <BoxCenter>
          <List>
            <li>
              <MdCheck /> 7 Dias Grátis;
            </li>
            <li>
              <MdCheck /> Sem taxa de instalação;
            </li>
            <li>
              <MdCheck /> Site para imobiliária pronto em segundos;
            </li>
            <li>
              <MdCheck /> Aplicativo para atender seus clientes;
            </li>
          </List>
        </BoxCenter>
        <BoxRight>
          <Spacer />
          <Signup />
        </BoxRight>
      </Container>
    </Wrapper>
  );
}

export default Cta;
