import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import anime from "animejs";

const defaultProps = {
  tagName: "span",
  children: null
};

const propTypes = {
  tagName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ])
};

function Number({ tagName: Component, children: childrenProp }) {
  const [children, setChildren] = useState(childrenProp);
  const component = useRef();

  useEffect(() => {
    let object = {
      value: parseInt(children, 10)
    };

    anime({
      targets: object,
      value: childrenProp,
      easing: "linear",
      duration: "300",
      round: 1,
      update: () => {
        if (component && component.current) {
          component.current.innerHTML = object.value;
        }
      },
      complete: () => {
        setChildren(childrenProp);
      }
    });
  }, [children, childrenProp]);

  return <Component ref={component}>{children}</Component>;
}

Number.defaultProps = defaultProps;
Number.propTypes = propTypes;

export default Number;
