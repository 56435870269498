import React from "react";
import { Wrapper, Header, Content } from "./styles";
import Price from "../Price";

function CardPlan({ title, price, children }) {
  return (
    <Wrapper>
      <Header>
        <h3>{title}</h3>
      </Header>
      <Content>
        <Price value={price} />
        {children}
      </Content>
    </Wrapper>
  );
}

export default CardPlan;
