import React, { useState } from "react";
import PropTypes from "prop-types";

import MonthlyValue from "../MonthlyValue";
// Styles
import { Wrapper } from "./styles";

const defaultProps = {
  users: 1
};

const propTypes = {
  users: PropTypes.number
};

const MONTHS = [
  { title: "Anual", month: 12 },
  { title: "Semestral", month: 6 },
  { title: "Trimestral", month: 3 },
  { title: "Mensal", month: 1 }
];

function ListCheck({ month, users }) {
  const [monthSelected, setMonth] = useState(month || 1);

  function onClickMonth({ month }) {
    setMonth(month);
  }

  return (
    <Wrapper>
      {MONTHS.map(({ title, month }) => (
        <MonthlyValue
          key={title}
          title={title}
          month={month}
          users={users}
          selected={monthSelected}
          onClick={onClickMonth}
        />
      ))}
    </Wrapper>
  );
}

ListCheck.defaultProps = defaultProps;
ListCheck.propTypes = propTypes;

export default ListCheck;
