import styled, { css } from "styled-components";
import { Breakpoints } from "config-styles";

export const Wrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  background: #ffffff;
  border: 1px solid #d3dceb;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;

  ${Breakpoints.tabletMax} {
    flex-direction: column;
  }

  ${(p) =>
    p.theme.isChecked &&
    css`
      border-color: #0084f4;
    `}

  & + & {
    margin-top: 15px;
  }

  > div {
    //flex-shrink: 0;
  }
`;

export const Price = styled.strong`
  font-size: 18px;
  font-weight: 800;
`;

export const Col = styled.div`
  ${Breakpoints.tabletMax} {
    text-align: center;
    margin: 5px 0;
  }
`;

export const ColRadio = styled(Col)`
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 24px;

  ${Breakpoints.tabletMax} {
    display: none;
  }

  svg {
    ${(p) =>
      p.theme.isChecked &&
      css`
        color: #0084f4;
      `}
  }
`;

export const ColPrice = styled(Col)`
  width: 105px;
  flex-shrink: 0;

  h4 {
    margin-bottom: 5px;
  }
`;

export const ColDetails = styled(Col)`
  flex-grow: 1;

  em {
    color: #493d4b;
  }
`;

export const ColPromotion = styled(Col)`
  flex-shrink: 0;
`;

const labelColors = ({ color }) => {
  switch (color) {
    case "primary":
      return css`
        color: #bd2e00;
        background-color: #ffd9cc;
      `;
    case "secondary":
    default:
      return css`
        color: #0b2037;
        background-color: rgba(11, 32, 55, 0.15);
      `;
  }
};

export const Label = styled.span`
  padding: 0.3em 0.8em;
  font-size: 14px;
  margin-top: -0.3em;
  margin-bottom: -0.3em;
  border-radius: 2em;

  transition: opacity 0.3s, background-color 0.3s;
  will-change: opacity, background-color;

  ${Breakpoints.mobile} {
    margin-bottom: 0.5em;
  }

  ${labelColors}
`;
