import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  border-radius: 4px;
  user-select: none;
  flex-grow: 1;
  margin: 35px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  background: none;
  border: 0 none;
  width: 1em;
  height: 1em;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #000;
    `}
`;

export const Number = styled.strong`
  font-size: 70px;
  margin: 0 15px;
  min-width: 85px;
  text-align: center;
  font-weight: 500;
`;

export const HelpText = styled.p`
  font-size: 12px;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s;
  font-weight: 500;

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`;
