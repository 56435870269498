import React from "react";
import CardPlan from "components/PlanView/components/CardPlan";
import { ListWrapper, List, Item, TextWrapper } from "./styles";
import { MdCheckCircle, MdHelp } from "react-icons/md";
import { useModalState } from "../../../../containers/ModalProvider";
import { ActionModals } from "../../../../containers/ModalRoot/module";

const CustomItem = ({ children }) => (
  <Item>
    <MdCheckCircle /> <span>{children}</span>
  </Item>
);

const EMAILS_TEXT = `- Auxílio nas configurações iniciais;
- Suporte na migração de dados (a migração deve seguir o padrão XML solicitado ou ser proveniente de softwares compatíveis);
- Apoio na configuração de integrações;
- Demonstração de recursos úteis adaptados às necessidades do corretor;
- Acompanhamento nos primeiros dias de uso.
`;

function CardYearly() {
  const [, dispatch] = useModalState();

  return (
    <CardPlan
      title={
        <>
          Plano Anual <small>(20% desconto)</small>
        </>
      }
      price={"79,92"}
    >
      <TextWrapper>
        <p>Pagamento Anual de R$ 959,04</p>
        <p>dividido em 4x no boleto ou 5x no cartão.</p>
      </TextWrapper>

      <ListWrapper>
        <div>
          <h4>Economize e ainda ganhe:</h4>
          <List>
            <CustomItem>01 hora técnica de design</CustomItem>
            <CustomItem>
              Implantação completa{" "}
              <span
                className="pointer"
                data-position="top"
                onClick={() => {
                  dispatch(ActionModals.openModalImplementation());
                }}
              >
                <MdHelp fill="#0060c3" style={{ marginLeft: "5px" }} />
              </span>
            </CustomItem>
            <CustomItem>Até 01 ano grátis de Imovelweb***</CustomItem>
          </List>
        </div>
      </ListWrapper>
    </CardPlan>
  );
}

export default CardYearly;
