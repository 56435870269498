import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
`;
export const Header = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #e4f4ff;
  text-align: center;
  width: 100%;
  padding: 19px 15px;
`;
export const Content = styled.div`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f7fafc;
  padding: 15px;

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
`;
