export const QUESTIONS = [
  {
    id: "Q1",
    name: "Preciso cumprir algum contrato de fidelidade?",
    acceptedAnswer:
      "Não! O Tecimob não possui contrato de fidelidade. Se em algum momento você quiser cancelar, basta não efetuar mais pagamentos.",
  },
  {
    id: "Q2",
    name: "Tenho que pagar algo além do valor do plano?",
    acceptedAnswer:
      "Não! O único valor a ser investido é o apresentado no plano escolhido por você. Não cobramos taxa de instalação e nem possuímos clausulas de fidelidade.",
  },
  {
    id: "Q3",
    name: "Preciso contratar algum plano de hospedagem?",
    acceptedAnswer:
      "Não. Em todos os planos a hospedagem do site e das contas de e-mail já esta incluída, você não precisa pagar nada mais por isso.",
  },
  {
    id: "Q4",
    name: "Por quanto tempo posso testar?",
    acceptedAnswer:
      "Você pode testar o sistema com todos os recursos por 7 dias. Depois disso, você tem a opção de continuar com o sistema ou então desligar sua conta.",
  },
];

export const FAQ_QUESTIONS = [
  {
    name: "Por que investir em um site para imobiliária?",
    answer: `<p>Ter o seu próprio <strong>site imobiliário</strong>, além de transmitir mais credibilidade para seus clientes e ser um ótimo portfólio para seus imóveis, também te ajuda a divulgar seus imóveis. Você pode integrar os imóveis cadastrados em seu site com os melhores portais imobiliários, e ainda investir na divulgação do seu site imobiliário junto ao Google.</p>`,
  },
  {
    name: "Por que escolher o Tecimob?",
    answer: `<p>O Tecimob está, segundo os nossos clientes, entre as melhores soluções de <strong>site para imobiliária e corretores de imóveis</strong>. Possuímos milhares de clientes satisfeitos em todo o Brasil, e uma equipe comprometida com o sucesso de cada corretor.</p>
    <p>Clientes Tecimob vendem mais porque aborda um lead interessado mais de uma vez dentro do período entre a procura e a compra de um imóvel, que estatisticamente leva até 9 meses.</p>
    <p>Mas como? Ao atender um cliente, receber um contato via site, portal, ou ainda ao compartilhar um imóvel via WhatsApp com seu cliente, nosso <strong>CRM imobiliário</strong> gera automaticamente um perfil de interesse para a pessoa. Feito isso, você será avisado sempre que um imóvel compatível com as características do perfil cadastrado for encontrado.</p>
    <p>Desta forma, você tem novas chances de oferecer o imóvel certo para a pessoa certa, demonstrando ao cliente o seu compromisso em ajudá-lo e consequentemente aumentando suas vendas.</p>`,
  },
  {
    name: "É possível migrar meus dados de outro sistema para o Tecimob?",
    answer: `<p>Sim, nossa equipe utiliza os formatos de XML mais populares para realizar a importação de seus imóveis, e também consegue importar os clientes por planilha Excel. Entre em contato para saber mais.</p>`,
  },
  {
    name: "Consigo usar meu domínio próprio?",
    answer: `<p>Sim, você pode utilizar um domínio já existente com seu site no Tecimob. Nossa equipe de suporte te ajuda na configuração. Caso deseje registrar um novo domínio, nós realizamos o intermédio de registro e configuração sem nenhuma cobrança adicional, além da taxa cobrada pelo registro.br (órgão federal de registro de domínio) no valor de R$40,00 ao ano.</p>`,
  },
  {
    name: "É possível integrar com os portais imobiliários?",
    answer: `<p>Sim, o Tecimob integra com os principais portais imobiliários do Brasil. Assim, você não precisará cadastrar seus imóveis nos portais e ganhará tempo para focar no que realmente importa: vender mais.</p>`,
  },
  {
    name: "É possível implantar o Pixel do Facebook?",
    answer: `<p>Sim, você pode inserir o Pixel do Facebook, assim como outros scripts externos importantes para o marketing do seu site imobiliário.</p>`,
  },
  {
    name: "Os sites do Tecimob são responsivos e otimizados para o Google?",
    answer: `<p>Sim, os sites do Tecimob funcionam bem tanto no celular quanto no computador, além de serem otimizados para os mecanismos de busca, como o Google.</p>`,
  },
  {
    name:
      "Quais os custos para criar um site para corretor de imóveis ou imobiliária?",
    answer: `<p>A partir de R$99,90 ao mês, você já pode ter seu <strong>site imobiliário completo</strong> com hospedagem, CRM e conta de e-mail com seu domínio funcionando. Sem contrato de fidelidade nem surpresas desagradáveis. <a href="/planos">Ver planos</a></p>`,
  },
  {
    name: "Por que utilizar um CRM imobiliário?",
    answer: `<p>Com o <strong>CRM imobiliário</strong> do Tecimob, os corretores vendem mais, porque aborda um interessado mais de uma vez dentro do período entre a procura e a compra de um imóvel.</p>
    <p>Ao atender um cliente, receber um contato via site ou portal, ou ainda compartilhar um imóvel com ele no WhatsApp, o CRM automaticamente gera o registro e um perfil de interesse para a pessoa. Feito isso, você será avisado sempre que um imóvel compatível com as características do perfil for cadastrado ou editado. Sendo assim, caso a venda não tenha sido concretizada no primeiro contato, você possui novas chances de oferecer o imóvel certo para a pessoa certa, demonstrando ao cliente o seu compromisso em ajudá-lo e aumentando a chance de efetivar o negócio.</p>
    <p>Além disso, você define e acompanha o progresso do cliente em cada etapa. Se você sempre souber em qual etapa da negociação seus clientes se encontram, poderá organizar e maximizar o rendimento do seu dia e maximizar suas vendas.</p>`,
  },
  {
    name: "Como criar meu site para imobiliária integrado ao CRM?",
    answer: `<p>Para criar seu site, basta se cadastrar no Tecimob. Ao criar sua conta, seu site já estará pronto e em poucos cliques você consegue deixá-lo do seu jeito e publicar os seus imóveis de forma fácil e intuitiva.</p>
    <p>Criando seu <strong>site imobiliário</strong> no Tecimob, ele já vem com CRM integrado, não precisa fazer mais nenhum tipo de ação.</p>`,
  },
];
