import styled from "styled-components";
import { Section, Container } from "components/Layout/styles";
import { H2, P } from "components/Typograph";

export const Box = styled.div`
  flex: 1 0;

  @media all and (max-width: 40em) {
    width: 100%;
  }
`;

export const Wrapper = styled(Section)`
  color: #fff;
  background-color: #1c0c1e;

  ${Container} {
    display: flex;
    align-items: center;

    @media all and (max-width: 40em) {
      flex-direction: column;
    }
  }

  ${H2} {
    color: #fff;
    margin-bottom: 20px;

    @media all and (max-width: 40em) {
      font-size: 25px;
      margin-bottom: 0.3em;
    }
  }

  ${P} {
    color: #fff;
    line-height: 28px;
  }
`;

export const List = styled.ul`
  svg {
    vertical-align: middle;
  }
  li + li {
    margin-top: 20px;
  }
`;

export const BoxLeft = styled(Box)`
  flex: 1 0;

  ${P} {
    font-size: 14px;
  }

  @media all and (min-width: 40em) {
    padding-right: 80px;
  }
`;

export const BoxCenter = styled(Box)`
  @media all and (max-width: 40em) {
    margin: 15px 0;
  }
`;

export const Spacer = styled.div`
  flex: 1 0;
`;

export const BoxRight = styled.div`
  text-align: right;

  @media all and (max-width: 40em) {
    text-align: center;

    ${Spacer} {
      display: none;
    }
  }
`;
