import React from "react";
import Number from "../../../Number/index";
import { UnderlinedPrice } from "./styles";

export const formatter = new Intl.NumberFormat("de-DE", {
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const getTitle = ({ title, month, netMonthlyPrice }) => {
  // Se o valor liquido mensal for igual ao valor total
  if (month === 1)
    return (
      <>
        <strong>{title}</strong>:
        <strong style={{ marginLeft: "5px" }}>
          R$<Number>{netMonthlyPrice}</Number>
        </strong>
      </>
    );

  return (
    <>
      <strong>{title}</strong>: Equivalente à
      <strong style={{ marginLeft: "5px" }}>
        R$<Number>{netMonthlyPrice}</Number>
      </strong>
      /mês
    </>
  );
};

export const getText = ({
  month,
  totalNetValue,
  totalGrossValue,
  hasDiscount
}) => {
  if (month === 1) return "Pago mensalmente";

  // Se não tiver desconto mostra o valor liquido total
  if (!hasDiscount) return <>Pagamento único de R${totalNetValue}</>;

  return (
    <>
      Pagamento único de <UnderlinedPrice>R${totalGrossValue}</UnderlinedPrice>{" "}
      por{" "}
      <strong>
        R$
        {totalNetValue}
      </strong>
    </>
  );
};
