import React from "react";
import { Wrapper, Content } from "./styles";
import Resources from "./components/Resources";
import Prices from "./components/Prices";
import Footer from "./components/Footer";

export default function PlanView() {
  return (
    <Wrapper>
      <Content>
        <div>
          <Resources />
        </div>
        <div>
          <Prices />
        </div>
      </Content>
      <Footer />
    </Wrapper>
  );
}
