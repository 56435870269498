import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "services";

export const PlanContext = createContext({
  data: {
    discount_6m: null,
    discount_12m: null,
    mailbox_price: null,
    monthly_price: null,
    subscription_price: null,
    user_price: null,
    yearly_price: null,
  },
  request: null,
  isLoading: null,
});

export const PlanProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const request = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.get("project-site/prices");

      setData(data.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <PlanContext.Provider value={{ isLoading, data, request }}>
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => useContext(PlanContext);
