import React from "react";
import { MdCheckCircle, MdHelp } from "react-icons/md";
import { Wrapper, Content, Header, List, Item } from "./styles";

const CustomItem = ({ children }) => (
  <Item>
    <MdCheckCircle /> <span>{children}</span>
  </Item>
);

const EMAILS_TEXT = `Envie e receba e-mails com
  seu dominio integrado ao
  Gmail ou Hotmail.
`;

export default function Resources() {
  return (
    <Wrapper>
      <Header>Todos os Recursos Inclusos:</Header>
      <Content>
        <List>
          <CustomItem>01 Usuário*</CustomItem>
          <CustomItem>Site Completo</CustomItem>
          <CustomItem>Hotsites Ilimitados</CustomItem>
          <CustomItem>Imóveis Ilimitados</CustomItem>
          <CustomItem>Contatos e Leads Ilimitados</CustomItem>
          <CustomItem>
            Emails Ilimitados{" "}
            <span data-tooltip={EMAILS_TEXT} data-position="top">
              <MdHelp fill="#0060c3" style={{ marginLeft: "5px" }} />
            </span>
          </CustomItem>
          <CustomItem>Aplicativo Completo</CustomItem>
          <CustomItem>Plugin Whatsapp</CustomItem>
          <CustomItem>CRM Completo</CustomItem>
          <CustomItem>Notificação via Push do Celular</CustomItem>
          <CustomItem>Facebook Leads e Store</CustomItem>
          <CustomItem>Diversos Relatórios</CustomItem>
          <CustomItem>Integração com Portais</CustomItem>
          <CustomItem>Rodízio de Leads para Corretor</CustomItem>
          <CustomItem>Recebimento de Leads de Portais</CustomItem>
          <CustomItem>Radar de Clientes por Captação</CustomItem>
          <CustomItem>Comparador Imóveis</CustomItem>
        </List>
        <List>
          <CustomItem>Roteiro de Visitas</CustomItem>
          <CustomItem>Ficha de Visita</CustomItem>
          <CustomItem>Fichas de Autorização</CustomItem>
          <CustomItem>Visão Unificada da Equipe</CustomItem>
          <CustomItem>Emails para Proprietário</CustomItem>
          <CustomItem>Gerador de Perfil Automático</CustomItem>
          <CustomItem>Integrado ao Google Agenda</CustomItem>
          <CustomItem>Integração com DWV e Órulo**</CustomItem>
          <CustomItem>Marca D’água</CustomItem>
          <CustomItem>Permissões e Privacidade</CustomItem>
          <CustomItem>Anexo de Documentos</CustomItem>
          <CustomItem>Formulário na Galeria de Fotos</CustomItem>
          <CustomItem>Área para Postagens</CustomItem>
          <CustomItem>Gerador de Cartão Virtual</CustomItem>
          <CustomItem>PopUp de Recepção no Site</CustomItem>
          <CustomItem>Suporte via Whatsapp</CustomItem>
          <CustomItem>Controle e suporte a LGPD</CustomItem>
        </List>
      </Content>
    </Wrapper>
  );
}
