import React from "react";
import PropTypes from "prop-types";
import { MdRemoveCircle, MdAddCircle } from "react-icons/md";
// Components
import { Wrapper, Number, Button, Content, HelpText } from "./styles";

const defaultProps = {
  min: 1,
  max: 99999,
  value: 1,
  maxText: "Para mais de 99999, entre em contato"
};

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  maxText: PropTypes.string
};

function Counter({ className, value, min, max, maxText, onChange }) {
  const isDecreaseDisabled = value === min;
  const isIncreasedDisabled = value === max;

  const increaseValue = () => {
    if (value + 1 >= max) return onChange(max);
    onChange(value + 1);
  };

  const decreaseValue = () => {
    onChange(value - 1);
  };

  return (
    <Wrapper className={className}>
      <Content>
        <Button
          aria-label="Diminuir número de corretores"
          disabled={isDecreaseDisabled}
          onClick={decreaseValue}
        >
          <MdRemoveCircle />
        </Button>
        <Number>{value}</Number>
        <Button
          aria-label="Aumentar número de corretores"
          disabled={isIncreasedDisabled}
          onClick={increaseValue}
        >
          <MdAddCircle />
        </Button>
      </Content>
      <HelpText isVisible={isIncreasedDisabled}>{maxText}</HelpText>
    </Wrapper>
  );
}

Counter.defaultProps = defaultProps;
Counter.propTypes = propTypes;

export default Counter;
