import styled from "styled-components";
import { Breakpoints } from "config-styles";
import { H4, P } from "components/Typograph";
import { Wrapper as Button } from "components/Button/styles";

export const Wrapper = styled.section`
  max-width: 1110px;
  margin: 0 auto;
  display: flex;

  ${Breakpoints.tabletMax} {
    flex-direction: column;
  }
`;

export const Title = styled(H4).attrs(() => ({
  as: "h3",
}))``;

export const Header = styled.header`
  width: 100%;

  ${Title} {
    margin-bottom: 0.7em;
  }
`;

export const Text = styled(P)`
  font-size: 14px;
`;

export const Box = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

/**
 * Resources
 */
export const ResourcesWrapper = styled(Box)`
  min-width: 210px;
  flex-shrink: 0;

  ${Breakpoints.tabletMax} {
    padding: 20px;
  }

  ${Breakpoints.desktop} {
    padding-right: 35px;
  }
`;

export const ResourceList = styled.ul`
  list-style: none;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const ResourceItem = styled.li`
  font-size: 14px;
  padding: 0.65em 0;

  [data-tooltip] {
    text-decoration: underline;
  }

  svg {
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

/**
 * Counter
 */
export const CounterWrapper = styled(Box)`
  min-width: 350px;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Breakpoints.tabletMax} {
    padding: 0 20px;
  }

  ${Breakpoints.desktop} {
    padding-left: 35px;
    padding-right: 35px;
  }

  ${Text} {
    margin-bottom: 25px;
  }
`;

export const PaymentWrapper = styled(Box)`
  flex: 3 0;

  ${Header} {
    ${Breakpoints.tabletMin} {
      padding-left: 35px;
    }
  }

  ${Breakpoints.desktop} {
    padding-bottom: 0;
    min-width: 525px;
  }

  ${Breakpoints.tabletMax} {
    padding: 20px;
  }
`;

export const Access = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 40em) {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  @media all and (max-width: 40em) {
    flex-direction: column;
    text-align: center;
    padding: 40px 0 20px;

    ${Button} {
      margin-top: 15px;
    }
  }
`;
