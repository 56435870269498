import PropTypes from "prop-types";
// Componets
//import MainTitle from "components/MainTitle";
//import Carousel from "components/Carousel";
//import Testimonial from "components/Testimonial";
// Data
// Styles
//import { Container } from "components/Layout/styles";
//import { Wrapper } from "sections/global/Testimonials/styles";
// Services
//import { getAll } from "services/depoiments";

//const renderSlides = data =>
//  data &&
//  data.map(testimonial => (
//    <Testimonial key={`testimonial-${data.id}`} data={testimonial} />
//  ));

function Testimonials() {
  return null;

  //return (
  //  <Wrapper color={background}>
  //    <Container>
  //      <MainTitle title="Parcerias de sucesso" />
  //
  //      <Carousel
  //        adaptiveHeight
  //        dots
  //        infinite
  //        speed={500}
  //        slidesToShow={3}
  //        slidesToScroll={3}
  //        responsive={[
  //          {
  //            breakpoint: 1024,
  //            settings: {
  //              slidesToShow: 3,
  //              slidesToScroll: 3,
  //              infinite: true,
  //              dots: true
  //            }
  //          },
  //          {
  //            breakpoint: 768,
  //            settings: {
  //              slidesToShow: 1,
  //              slidesToScroll: 1,
  //              dots: false,
  //              arrows: false
  //            }
  //          }
  //        ]}
  //      >
  //        {renderSlides(getAll().data)}
  //      </Carousel>
  //    </Container>
  //  </Wrapper>
  //);
}

Testimonials.defaultProps = {
  background: "#fff"
};

Testimonials.propTypes = {
  background: PropTypes.string
};

export default Testimonials;
