import styled from "styled-components";

export const Wrapper = styled.section``;
export const List = styled.ul`
  line-height: 34px;
`;
export const Item = styled.li`
  font-size: 14px;
  color: #272727;

  svg {
    vertical-align: middle;
    color: ${(p) => p.theme.colors.success};

    :first-child {
      margin-right: 5px;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const Header = styled.h1`
  font-weight: 600;
  font-size: 18px;
  color: #272727;
  margin-bottom: 10px;
`;
