import React from "react";
// Components
import Cta from "sections/global/Cta";
import MainTitle from "components/MainTitle";
import Seo from "components/Seo";
import Layout from "components/Layout";
import PlanSelect from "components/PlanSelect";
import PlanView from "components/PlanView";
// Sections - Global
import Testimonials from "sections/global/Testimonials";
// Sections - Planos
import FrequentlyQuestions from "sections/planos/FrequentlyQuestions";
// import {
//   FAQ_QUESTIONS,
//   QUESTIONS,
// } from "sections/inicio/FrequentlyQuestions/data";

export default function Index(props) {
  return (
    <Layout {...props}>
      <Seo
        title="Clientes Tecimob vendem mais!"
        description={`Sem taxa de instalação, sem contratos de fidelidade e sem surpresas. Acesso imediato. Seu site pronto e entregue na hora!`}
      />
      <MainTitle
        titleTag="h1"
        title="Clientes Tecimob vendem mais!"
        text={
          <>
            Sem taxa de instalação, sem contratos de fidelidade e sem surpresas.
            <br /> Acesso imediato. Seu site pronto e entregue na hora!
          </>
        }
      />
      <PlanView />
      <FrequentlyQuestions />
      <Testimonials />
      <Cta />
    </Layout>
  );
}
